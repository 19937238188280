.BackButton {
  min-width: 9rem;
  cursor: pointer;
  z-index: 10;
  position: relative;

  &__Icon {
    margin: 0 1rem;
  }
  &__Icon:hover > svg > g > g {
    fill: white;
  }
}