.vehicleMassLoad{
    min-height: 742px;
}

.vehiclesForSales{
    margin-top: 400px;
}

.vehicleMassLoadBackButtom{
    margin-left: 1rem;
    margin-right: 1rem;
}
