.CustomerPodium__container {
     margin: 1.188rem 0 1.563rem;
     padding: 2.563rem 2.25rem 1.375rem;
     box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
     background-color: #ffffff;
}
 .CustomerPodium__header{
     display: flex;
     justify-content: space-between;
}
 .CustomerPodium__title{
     font-size: 1.4rem;
     text-transform: uppercase;
}
 .CustomerPodium__flex {
     display: flex;
     align-items: center;
     grid-gap: 0.625rem 1.563rem;
}
 .CustomerPodium__container-image{
     text-align: center;
}
 .CustomerPodium__profile-image {
     width: 12.5rem;
     height: 12.5rem;
     border: 4px solid;
     border-radius: 100px;
}
 @media (max-width: 600px) {
     .CustomerPodium__profile-image {
         width: 7.5rem;
         height: 7.5rem;
    }
}
 .CustomerPodium__profile-info {
     width: 100%;
}
 .CustomerPodium__profile-subtitle {
     margin: 1.188rem 0 1.563rem;
     font-size: 18px;
     text-transform: uppercase;
     color: #d27b4d;
}
 .CustomerPodium__profile-grid {
     margin: 1.188rem 0 1.563rem;
     display: grid;
     grid-gap: 0.438rem 1.563rem;
     grid-template-columns: repeat(3, 1fr);
     grid-template-rows: repeat(2, 1fr);
}
 .CustomerPodium__profile-grid-title {
     text-transform: uppercase;
     font-size: 0.813rem;
     font-weight: 600;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: 0.011rem;
     text-align: left;
     color: #333333;
}
 .CustomerPodium__profile-grid-desc {
     font-size: 0.938rem;
     font-weight: normal;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: 0.012rem;
     text-align: left;
     color: #333333;
}
 .CustomerPodium__footer{
     display: flex;
     align-items: center;
     justify-content: space-between;
}
 .CustomerPodium__range{
     position: relative;
     bottom: 1.063rem;
     padding: 0.5rem 1.25rem;
     border-radius: 5px;
     color: #FFF;
     z-index: 10;
     width: 8rem;
     margin: 0 auto;
}
 .CustomerPodium__range-icon{
     width: 15px;
     marginRight: 10px;
}
 .CustomerPodium__range-title{
     text-transform: capitalize;
}
