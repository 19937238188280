.PDFModal {
  &__Pdf {
    margin: 0 auto;
    width: 100%;

    iframe {
      height: 74vh;
      width: 100%;
    }
  }
}