.dropFile{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fcfcfc;
    flex-direction: row;
    overflow-y: scroll;
}

.dropFile.empty {
    border: dashed 2px #e2e2e2;
}

.dropFile.hasFile {
    border: solid 2px #e2e2e2;
}

.dropFile.dragActive{
    border: solid 2px #007aff;
}

.dropFile.dragRejected{
    border: solid 2px #ff6476 !important;
}

.list{
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
}

.list>li{
    width: 100%;
    height: 50px;
    line-height: 50px;
    list-style: none;
    padding: 0px 24px 0px 24px;
    background-color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.list>li:nth-child(even){
    background-color: #f2f3f8;
}

.comment {
    font-size: 12px;
    font-weight: normal;
}
.comment span {
    display: inline-block;
    height: 25px;
    padding: 3px;
}