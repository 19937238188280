.link {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #007aff !important;

  &__icon{
    > svg {
      g {
        fill: #9ea3b1;
      }
      margin-right: .8rem;
      height: 1.2rem;
    }
  }
}