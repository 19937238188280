.VerificationPointModal {
  min-height: 80vh;

  &__Container {
    max-width: 49.375rem !important;
  }

  &__Content {
    height: 80vh !important;
    max-height: 46.063rem !important;
    overflow: auto;
    overflow-x: clip;
    &--Image-container {
      min-height: 435px;
    }

    .Remarks {
      overflow: auto;
    }
  }

  &__Body {
    flex: initial !important;
  }

  &__Image {
    width: 91%;
  }

  &__Paginator {
    width: 107% !important;
    margin: 0;
    padding: 0;
    position: absolute;
    margin-left: -5.5% !important;
    top: 42%;
  }
}