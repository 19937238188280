.Summary {
  padding: 0px !important;
  position: sticky;
  top: 1.5rem;
  > .header {
    background-color: #040a20;
    padding: 2rem;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  > .vehicle-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    > span {
      position: absolute;
      right: 1rem;
      font-weight: bold;
      color: #007aff;
      cursor: pointer;
    }
    > p {
      > .title {
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.19px;
      }
    }
    > .vehicle-image {
      width: 100%;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    border-bottom: solid 1px #c5c5c5;
  }

  .schedule-dates {
    padding: 1rem;
    background-color: #fcfcfc;

    > .date {
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      align-items: center;
      > .icon {
        margin-right: 0.8rem;
        svg {
          path,
          g {
            fill: #007aff;
          }
          #Group-2 {
            stroke: #007aff;
          }
        }
      }

      .sched {
        font-weight: 0.75rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        .date {
          color: #9ea3b1;
        }
        .title {
          text-transform: uppercase;
          color: #333333;
        }
        .branch {
          font-weight: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #9ea3b1;
        }
      }
    }
  }

  .plan {
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333333;
    padding: 1rem;
    text-transform: uppercase;
    margin-bottom: 0;
    span {
      font-weight: bold;
    }
  }

  > .promocode {
    padding: 1rem;
    padding-right: 0rem;
    > .question {
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #333333;
    }
  }

  .discount {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    &-edit {
      font-size: 0.8rem;
      position: absolute;
      right: 1rem;
      font-weight: bolder;
      color: #007aff;
      cursor: pointer;
    }

    label.composed-input-label {
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.78px;
      color: #9ea3b1;
      text-transform: uppercase;
    }
    .discount-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      .options {
        width: 30%;
        .OptionsBar__small > ul > li {
          padding: 0.83rem 0.375rem;
        }
      }

      .number {
        width: 70%;
      }
    }
  }
}
