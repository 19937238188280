.CardInfo {

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    max-width: 23.125rem;
    max-height: 11.188rem;
    min-height: 9rem;
  }

  &__info-container {
    width: 100%;

    .text-info {
      font-size: 0.875rem;
    }

    &--Spec-item {
      margin-bottom: 1.1rem;
    }
  }

  &__image {
    height: 100%;
    width: 5rem !important;
  }

  &__Remarks {
    &--container {
      overflow-wrap: break-word;
    }
  }
}