.contract {
  &__actions-bar {
    height: 2.625rem;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 1.25rem;
    padding-right: 1.875rem;
    justify-content: space-between;
  }

  &__car-list {
    height: 50vh;
    overflow-y: scroll;
    &__element {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      max-width: 80rem;
      width: 100%;
      margin: 0 auto;

      &--no-selectable {
        cursor: not-allowed;
      }

      &__image {
        width: 5rem;
        > img {
          width: 100%;
        }
      }

      &__line {
        font-weight: bold;
        width: 8%;
      }

      &__brand {
        width: 10%;
      }

      &__status {
        display: flex;
        align-items: center;
      }
    }
  }

  &__filters {
    width: 14.75rem;
    &__element {
      position: relative;
      margin-bottom: 0.75rem;
      > .Checkbox {
        position: absolute;
        right: 0;
      }
    }
  }
  .btn-add {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 0.313rem;
  }
}
