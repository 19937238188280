.title {
  color: #007aff;
  font-weight: bolder;
}
.subTitle {
  font-weight: normal;
}

.leftBack {
  cursor: pointer;
}
