.iconXs {
  width: 24px;
  height: 24px;
  display: inline-block;
}

.iconSm {
  width: 24px;
  height: 27px;
  margin-right: 1rem;
  display: inline-block;
}

.iconMd {
  width: 38px;
  height: 48px;
  margin-right: 1rem;
  display: inline-block;
}

.iconLg {
  width: 72px;
  height: 81px;
  margin-bottom: 1rem;
}

.btn, .btn:after, .btn:before {
  transition: 0.12s;
}

.btn {
  display: inline-block;
  padding: 10px 24px 10px 12px;
  text-decoration: none;
  color: var(--primary);
  position: relative;
}

.btn>span {
  color: var(--primary);
  position: relative;
  z-index: 5;
}

.btn:hover>span {
  color: white;
}

.btn.active>span {
  color: white;
}

.btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid var(--primary);
  border-width: 2px 0 2px 0;
  transform: skewX(-30deg);
}
.first{
  margin-left: .75rem;
}
.first:before {
  content: "";
  position: absolute;
  top: 0;
  left: -.75rem;
  bottom: 0;
  width: 25px;
  border: 2px solid var(--primary);
  border-width: 2px 0 2px 2px;
  z-index: 8;
}

.second:before {
  content: "";
  position: absolute;
  top: 0;
  right: -.75rem;
  bottom: 0;
  width: 25px;
  border: 2px solid var(--primary);
  border-width: 2px 2px 2px 0;
  z-index: 8;
}

.btn:hover, .btn:focus {
  color: #fff;
}

.btn:hover:after, .btn:focus:after, .btn.active:after {
  background: var(--primary);
  border-color: var(--primary);
}

.btn:hover:before, .btn:focus:before, .btn.active:before {
  border-color: var(--primary);
  background: var(--primary);
}
.comment {
  padding: 25px 0 0 0;
  font-size: .75rem;
  font-weight: normal;
}
.comment span {
  display: inline-block;
  height: 25px;
  padding: 3px;
  margin-right: .5rem;
}

.btnDownload {
  font-size: 1rem;
}

.tableWrapper {
  min-height: 300px;
}