.CardNumber {
  width: 12.5rem;
  min-width: 12.5rem;
  max-width: 13rem;

  &__Header {
    height: 3.5rem;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    &--primary {
      background-color: var(--yellow) !important;
    }
    &--dark {
      background-color: #040a20 !important;
    }

    &Small {
      @extend .CardNumber__Header;
      height: 2.25rem !important;
    }
  }

  .Card-Body {
    height: 4rem;
    border: 4px solid #eceef4;
    border-top: 0px;
    border-radius: 10px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    &Small {
      @extend .Card-Body;
      height: 2.5rem !important;
    }
  }
}
