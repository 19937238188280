.VehicleParts {
  .vehicle-img {
    max-width: 25.375rem;
  }

  .Rectangle {
    &__container {
      justify-content: right;
      align-items: center;
    }
    &__Body {
      background-color: var(--primary);
      width: calc(100% + 5rem);
      margin-left: -1.563rem;
      padding-right: 1rem;
    }
  }

  .Forms {
    &__Container {
      max-height: 33rem;
      padding: 0.3rem;
      overflow-y: auto;
      overflow-x: clip;
    }
  }

  .vehicle-sections {
    width: 100%;
    height: 6.3rem;
    overflow: auto;

    &__container {
      display: flex;
    }
  }

  .mini-card {
    min-width: 6.26rem;
    max-width: 6.3rem;
    height: 6.26rem;
    margin: 0 0.5rem;

    &__custom-container {
      height: 100%;
    }

    &__active-border {
      &--active {
        border: solid var(--secondary) 0.2rem !important;
        border-radius: 0.688rem !important;
      }
      &--inactive {
        border: solid #e2e2e2 0.2rem !important;
        border-radius: 0.688rem !important;
        background: white !important;
      }
      &--disabled {
        border: solid #e2e2e2 0.2rem !important;
        border-radius: 0.688rem !important;
        background-color: #f2f3f8 !important;
      }
    }

    &__title {
      font-size: 0.75rem;
      margin-top: -0.3rem;
    }

    &__image {
      width: 100%;
      height: 100%;

      &-container {
        width: 4.5rem;
        height: 2.39rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__Select-mode {
    &--container {
      max-width: auto;
    }

    &--rectangle {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.625rem;
      border: solid 1px var(--primary);
      position: relative;

      &__primary-bg {
        background: var(--primary);
      }

      .shape {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        clip-path: polygon(54% 0, 100% 0%, 100% 100%, 46% 100%);
        &--active {
          background: var(--primary);
        }
        &--inactive {
          background: white;
        }
      }

      .shape-button {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none !important;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;

        :focus {
          outline: none !important;
        }
      }

      .delivery-button {
        @extend .shape-button;
      }

      .refund-button {
        @extend .shape-button;
        z-index: 5;
      }
    }
  }

  &__VehicleView {
    &--container {
      min-height: 17rem;
      max-height: 33rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; // Evita que el contenido se desborde
    }

    &--vehicle_image {
      max-width: 100%; // La imagen no excederá el 100% del ancho del contenedor
      max-height: 100%; // La imagen no excederá el 100% de la altura del contenedor
      object-fit: contain; // Mantiene la relación de aspecto de la imagen
      justify-content: center;
      align-items: center;
    }
  }

  &__VehicleSections {
    min-height: 8rem;
    margin-top: 1.6rem;
    padding: 0.1rem;
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    &--container {
      display: flex;
    }
  }

  &__HelperAditionalField {
    &--sub-text {
      min-width: 1.3rem;
    }
  }

  &__General {
    &--item {
      margin: 0.4rem 0;
    }

    &--RadioGroup {
      overflow: auto;

      &__input {
        min-width: 13rem;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.disable-pointer {
  cursor: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
