.OfferComponent{
  text-align: center;
  padding: 2rem  1rem;
  min-height: 10rem;
  align-items: center;
  position: relative;
  cursor: pointer;
  .icon-offer {
    position: absolute;
    top: 1rem;
    right: 2rem;
    cursor: pointer;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      width: 100%;
    }
  }

  > .body-container {

      > .offer-vehicle {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.19px;
        text-align: center;
        color: #333333;
        text-transform: uppercase;
      }

      > .offer-amount {
        font-size: .75rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.14px;
        text-align: center;
        color: #333333;
      }
  }
}