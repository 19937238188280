.ExtraChargesDetails {
  &__Card {
    &--image {
      width: 7.25rem;
    }
  }
  &__ContainerInvoice {
    background-color: #fbfbfb;
  }
  &__detailInvoice {
    padding-top: .8rem !important;
    min-width: 20rem;
  }
  &__priceTitle {
    color: #f2a000 !important;
    margin-top: -1rem;
  }
}