.plan-details {
  width: 31.6%;
  border-left: solid 1px #eff0f6;;
  > .plan-header {
    border-top-right-radius: 0.688rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    background-color: #333;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    padding: 1rem 1.25rem;

    > .control-left, .control-right {
      cursor: pointer;
      > svg > path {
        fill: white;
      }
    }
  }
  > .plan-data {
    padding: 1.5rem .5rem 2rem .75rem;
    > p {
      text-align: center;
      display: flex;
      flex-direction: column;

      .period {
        font-size: 0.875rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        text-transform: uppercase;
      }
    }
    > .plan-features {
      margin-top: 1rem;
      font-size: 0.875rem;
      .bold {
        font-weight: bold;
      }
      .plan-features-list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: auto;
        column-gap: 2rem;
        padding-left: 1.25rem;
        padding-top: 1rem;
      }
    }
  }
  .should-increase-days{
    padding: .5rem 1rem;
    position: relative;
    &__bg {
      top: 0;
      left: 0;
      position: absolute;
      min-width:100%;
      min-height:100%;
      opacity: 0.1;
      background-color: #ff6476;
    }
    &__text{
      text-align: center;
      color: #333;
      font-size: 0.625rem;
      opacity: 1;
      font-weight: bold;
    }
  }
}