.HospedayInformation {
  &__CheckInput {
    .right {
      font-size: 0.9rem;
      margin: 0;
      padding: 0;
      background: white;
    }
  }
}
