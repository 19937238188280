.contractContainer {
  max-width: 100%; /* Ancho máximo del contenedor */
  max-height: calc(
    2 * (25rem + 2rem)
  ); /* Máximo de dos filas (250px de altura de cada elemento de contrato + 20px de espacio entre ellos) */
  overflow-y: auto; /* Aplicar scroll vertical si hay más contenido */
  overflow-x: hidden; /* Ocultar scroll horizontal */
}

.contractGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(20rem, 1fr)
  ); /* Distribuir automáticamente los elementos en filas, con un mínimo de 250px y un máximo de 1fr */
  gap: 1rem; /* Espacio entre elementos */
}
