.SpecItem {
  &__reverse {
    flex-direction: row-reverse;
    justify-content: space-between !important;
  }

  &__sub-item {
    font-size: 0.8rem;
    margin-left: 0.2rem;
  }
}
