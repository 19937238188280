.SchedDate {
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  align-items: center;
  > .icon {
    margin-right: 0.8rem;
    svg {
      path,
      g {
        fill: #007aff;
      }
      #Group-2 {
        stroke: #007aff;
      }
    }
  }

  .sched {
    font-weight: 0.75rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    .date {
      color: #9ea3b1;
    }
    .title {
      text-transform: uppercase;
      color: #333333;
    }
    .branch {
      font-weight: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #9ea3b1;
    }
  }
}
