.OptionsBar {
  display: flex;

  &__full-with > ul {
    width: 100%;
  }

  > ul {
    list-style: none;
    padding-inline-start: 0;
    height: 100%;
    display: inherit;

    > li {
      position: relative;
      border-top: solid .125rem #e2e2e2;
      border-bottom: solid .125rem #e2e2e2;
      font-weight: bolder;
      font-size: 1rem;
      display: inline-block;
      padding: 1.125rem 1.6rem;
      border-right: solid .125rem #e2e2e2;
      text-align: center;

      .default {
        color: #007aff;
      }

      > label {
        margin-bottom: 0;
        width: 100%;
        > .icon {
          margin-right: .5rem;
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }

      p {
        font-size: 0.7rem;
      }

      .check-title {
        font-size: 1rem !important;
      }

      &:hover {
        cursor: pointer;
        background-color: #f2f3f8;
      }

      &:first-child{
        border-left: solid .125rem #e2e2e2;
        border-top-left-radius: .75rem;
      }

      &:last-child{
        border-top-right-radius: .75rem;
        border-right: solid .125rem #e2e2e2;
      }

      input[type="checkbox"] {
        display: none;
      }

      &.selected {
        background-color: #f2f3f8;
        &:after {
          z-index: 1;
          position: absolute;
          top: 99%;
          left: 65%;
          margin-left: -25%;
          content: '';
          width: 0;
          height: 0;
          border-top: solid 1rem #e2e2e2;
          border-left: solid 1rem transparent;
          border-right: solid 1rem transparent;
        }
      }
    }
  }

  &__disabled {
    > ul {
      > li {
        pointer-events: none;
        color: black !important;
     }
    }
  }

  &__only-icons{
    > ul {
      list-style: none;
      border: solid 0 transparent;
      display: flex;
      > li {
        padding: 0;
        border: solid .125rem #e2e2e2;
        border-radius: .5rem;
        margin-right: 1rem;
        width: 6rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child{
          border-right: solid .125rem #e2e2e2;
        }

        > label {
          > .icon {
            margin-right: 0;
            img {
              width: unset;
              height: unset;
              max-width: 5.5rem;
              max-height: 2.5rem;
            }
          }
        }

        &.selected {
          background-color: #f2f3f8;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  &__small{
    > ul {
      list-style: none;
      border: solid 0 transparent;
      font-weight: bold;
      text-transform: uppercase;
      > li {
        padding: .5rem 0.375rem;
        border-top: solid .125rem #e2e2e2;
        border-bottom: solid .125rem #e2e2e2;
        border-left: solid .125rem #e2e2e2;
        border-right: solid 0px transparent;
        min-width: 2rem;

        &:first-child{
          border-top-left-radius: .75rem;
          border-bottom-left-radius: .75rem;
        }

        &:last-child{
          border-top-right-radius: .75rem;
          border-bottom-right-radius: .75rem;
          border-right: solid .125rem #e2e2e2;
        }

        > label {
          > .icon {
            margin-right: 0;
          }
        }

        &.selected {
          background-color: #f2f3f8;
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
