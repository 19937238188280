.VehicleAssignment {
  .SearchList {
    max-height: 26rem;
    overflow: auto;
  }

  &__Card {
    padding: 0.938rem;
  }

  .CardRow {
    padding: 15px 11px !important;
    height: auto !important;
    width: auto !important;
    min-width: 43rem !important;

    .CardRow__Item {
      padding: 0 0.5rem;
    }
  }
}