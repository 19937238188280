$blue: #007aff;
$breakpoint-xs: 360px;
$breakpoint-sm: 767px;
$breakpoint-md: 1023px;
$breakpoint-lg: 1360px;
$breakpoint-xxl: 1400px;

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

.tabs {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}
.tabs-list {
  display: flex;
  justify-content: space-between;
  background-color: #f2f3f8;
  width: 60%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  border-top: 2px solid #dee2e6;
  border-right-width: 0px;
  border-left-width: 0px;
}

.tab-list-item {
  display: flex;
  list-style: none;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  justify-content: center;
  padding: 10px;
  color: $blue;
  border-right: 2px solid #dee2e6;
  border-top-right-radius: 8px;
}
.icon-tab {
  position: absolute;
  right: 10px;
  top: 10px;
}
.delete-borders--1 {
  border-left: 2px solid #dee2e6;
  border-top-left-radius: 8px;
  border-right: 0px;

  &:nth-child(3) {
    border-top-left-radius: 0px;
    border-right: 2px solid #dee2e6;
    border-left: 0px;
  }
}

.tab-list-active {
  background-color: white;
  border-right: 2px solid #dee2e6 !important;
  border-left: 2px solid #dee2e6 !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  height: 104%;
  color: black;
}
.tab-list-active img {
  display: none;
}
.add-gps {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.add-gps-tab {
  @extend .add-gps;
}
.add-gps p {
  margin-bottom: 0;
  margin-top: 1em;
  font-weight: bold;
}
.add-tab {
  height: 55px;
  width: 55px;
  display: flex;
  border: 2px solid #dee2e6;
  border-radius: 8px;
  background-color: white;
  @include transition(ease-in-out 0.3s);
  &:hover {
    border-color: #eceef4;
    outline: 0;
    box-shadow: 0 0 8px 0 rgba(0, 122, 255, 0.5);
    @include transition(ease-in-out 0.3s);
  }
}
.add-tab p {
  width: 100%;
  margin: auto 0;
  margin-bottom: 0px;
  text-align: center;
  color: $blue;
  font-size: 30px;
  font-weight: 900;
}
.tab-content {
  border-left: 0.125rem solid #dee2e6;
  border-right: 0.125rem solid #dee2e6;
  border-bottom: 0.125rem solid #dee2e6;
  border-top: 0.125rem solid #dee2e6;
  padding: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.click-tab {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  font-weight: 900;
  font-size: 23px;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 72%;
}
.w-100 {
  width: 100%;
}
