.wrapper {
  padding: 36px 30px;
  border-radius: 11px;
  /* border: 2px solid #dee2e6; */
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eceef4;
}
.n-orden {
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 0;
}

.card-gps {
  border-radius: 8px;
  border: solid 2px #eceef4;
  padding: 24px 25px;

  .gps-title {
    color: #f2a000;
    font-size: 33px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
  }
}

.tag {
  h6 {
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    color: #333333;
  }
  p {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.19px;
    color: #333333;
  }
}

.modal__title--center {
  text-align: center;
  width: 80%;
}
