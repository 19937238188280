.ReservationSchedule {
  display: flex;
  min-height: 2.625rem;
  width: 100%;
  background-color: #d27b4d;
  color: white;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: bold;
  overflow: auto;

  .scheduled-hour {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    min-width: 70%;

    .end {
      margin-left: 1rem;
    }

    .start, .end{
      display: flex;
      align-items: center;
      align-content: center;
      svg {
        margin-right: 1rem;
      }
    }
  }

  .edit-section {
    display: flex;
    cursor: pointer;
    margin-left: 1rem;
    svg {
      margin-left: .5rem;
      > path {
        fill: #007aff;
      }
    }
  }
}