.payment-type {
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;

    &__icon {
      cursor: pointer;
    }

    &__titles {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: flex-start;
    }
    &__right {
    }
  }
  &__content {
    margin-top: 2.25rem;
  }
}
