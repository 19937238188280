.pdf {
  p {
    font-size: 16px !important;
  }

  .card {
    border: 1.5px solid black;
  }

  .signature {
    border: 1.5px solid black;
    width: 300px;
    height: 200px;
  }
}