.Slider {
  .AllScreen {
    width: 20px;
    padding: 0 5px;
    position: absolute;
    right: 30px;
    height: 60%;
    max-height: 450px;
    margin: auto;
    top: 0;
    bottom: 0;
  }
}

.rc-slider-step {
  height: 100%;
  width: 10px !important;
}

.rc-slider-mark {
  top: 0;
  left: -18px !important;
  height: 100%;
  width: 100px;
}
