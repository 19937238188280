.Detail {
  &__customer {
    display: flex;
    min-height: 2.625rem;
    width: 95%;
    color: white;
    &__name {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      display: flex;
      align-items: center;
      background-color: #d27b4d;
      width: 67%;
      padding-left: 1.875rem;
    }
    &__folio {
      display: flex;
      align-items: center;
      background-color: #333;
      width: 37%;
      padding-left: 1.875rem;
      font-size: 1.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  &__invoice-summary {
    display: flex;
    &__data {
      width: 70%;
      padding: 2.1rem 4.375rem;
      &--no-data {
        align-self: center;
        align-items: center;
        justify-content: center;
        justify-items: center;
        align-content: center;
      }
    }

    &__amount {
      width: 30%;
      background-color: #fbfbfb;
      padding: 2.1rem 4rem;
    }
  }

  .vehicle-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      width: 95%;
    }
  }
  .vehicle-features {
    padding: 2.25rem 2.25rem 0rem 2.25rem;
    > p {
      > .kind {
        font-size: 0.625rem;
        text-transform: uppercase;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.4;
        letter-spacing: normal;
      }
      > .title {
        font-size: 1.5rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        margin-bottom: 1rem;
      }

      > .description {
        font-size: 0.875rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    > .features {
      margin-top: 0.75rem;
      > .title {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        margin-bottom: 0.813rem;
      }

      > .feature-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 0.75rem;
        grid-auto-rows: auto;
      }
    }
  }

  .plan-features {
    font-size: 0.875rem;
    padding: 2.25rem 2.25rem 2.25rem 0rem;
    .coverage {
      font-size: 0.75rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333;
      svg {
        margin-right: 1rem;
      }
    }
    .plan-features-list {
      margin-top: 1.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 2rem;
      grid-auto-rows: auto;
      padding-inline-start: 20px;
      > li {
        font-size: 1rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #333333;
        list-style: none;
        list-style-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12px' height='10px' viewBox='0 0 6 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!-- Generator: Sketch 53.2 (72643) - https://sketchapp.com --%3e%3ctitle%3ePath%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cg id='05-Cotización' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='01.03-Cotizar_Detalle_Descuento-dinero' transform='translate(-235.000000, -652.000000)' fill='%23F2A000' fill-rule='nonzero'%3e%3cpolygon id='Path' points='237.255227 655.583367 235.644283 653.998653 235 654.721723 237.315985 657 241 652.659254 240.296443 652'%3e%3c/polygon%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
      }
    }
  }
}
