.InvoiceDetail {
  // TODO: Add this style on the table component
  .d-flex.DropdownCell.text {
    margin: 0 auto !important;
  }

  td {
    white-space: nowrap;
  }
}
