.ExtraChargeSummary {
  &__Invoice {
    &--container {
      width: 100% !important;
      padding: 1.4rem 1rem 2rem 1rem !important;
    }
    &--amount-text {
      color: #f2a000 !important;
    }
  }
  &__Rectangle {
    width: 126%;
    min-height: 1.6rem;
    background-color: #040a20;
    color: var(--white);
    justify-content: center;
    display: flex;
  }
  &__Vehicle_image {
    width: 100%;
  }
}
