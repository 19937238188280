.OfferCarrousel{
  display: flex;
  margin-bottom: 3rem;
  &__title{
    font-size: 1.375rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 2.25rem;
  }
  &__elements {
    width: calc(100% - (1.875rem * 2));
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;

    > .CardSection {
      margin-bottom: 0;
    }
  }

  &__control {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    &__left {
      margin-right: 0.813rem;
    }
    &__right {
      margin-left: 0.813rem;
    }
  }
}