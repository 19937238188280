.StepTwo {
  .filters {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plans-container {
    max-height: calc(70vh);
    overflow-y: scroll;
  }
}
