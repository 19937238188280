.disclaimer-same-day {
  padding: .5rem;
  text-align: center;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  background-color: #020a30;
  color: white;
  position: absolute;
  width: 98%;
  bottom: 0;
  left: 1rem;
  margin-top: 1rem;
}