.CardRow {
  width: 100%;
  height: 64px;
  flex-grow: 0;
  margin: 11px 0 36px;
  padding: 15px 43px 15px 80px;
  border-radius: 11px;
  border: solid 1px var(--light-gray);
  background-color: #fff;
  display: flex;
  align-items: center;

  &__Row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  &__Item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.CardRow:hover {
  border: solid 1px var(--secondary);
}