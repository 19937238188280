.normal-text {
  font-weight: 500 !important;
}

.VehicleInfo {
  &__image {
    width: 100% !important;
  }

  &__CardPart {
    &:hover {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      border: solid 1px var(--secondary);
      background-color: var(--white);
      cursor: pointer;
    }
  }

}

.vehicle-features {
  padding: 2.25rem 2.25rem 0rem 2.25rem;
  > p {
    > .kind {
      font-size: 0.625rem;
      text-transform: uppercase;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.4;
      letter-spacing: normal;
    }
    > .title {
      font-size: 1.5rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      margin-bottom: 1rem;
    }

    > .description {
      font-size: 0.875rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
  > .features {
    margin-top: .75rem;
    > .title {
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      margin-bottom: 0.813rem;
    }

    > .feature-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      row-gap: .75rem;
      grid-auto-rows: auto;
    }
  }
}