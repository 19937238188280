.invoice-payment {
  &__invoice-row {
    padding: 1.5rem 1.875rem 1.5rem 4.25rem;
    border-radius: 0.5rem;
    border: solid 0.125rem #eceef4;
    margin-bottom: 0.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    &__close {
      cursor: pointer;
      position: absolute;
      top: -0.8rem;
      right: -0.8rem;
    }

    &__text-area textarea {
      max-height: 5rem;
    }
  }
}
