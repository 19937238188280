.ReservationDates {
  .date-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path,
      g {
        fill: #007aff;
      }
      #Group-2 {
        stroke: #007aff;
      }
    }
  }
}
