.payment-page {
    &__main {
      &__title {
        font-size: 1.75rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      &__icons {
        display: flex;
        flex-direction: row;
        .CardSection {
          min-height: unset !important;
        }
      }
  
      .disclaimer {
        > .disclaimer-title,
        .disclaimer-body {
          font-size: 1rem;
        }
      }
    }
  }
  
  .min-height-unset {
    min-height: unset !important;
  }
  
  .text-ellipsis {
    // This required max-width
    display: -webkit-box;
    // 4 lines
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .section-method-payment {
    border-bottom: 1px solid gray;
  }
  