.PlanDescription {
  display: flex;
  > .vehicle-details {
    display: flex;
    flex-direction: row;
    width: 68.4%;
    border-top-left-radius: 0.688rem;
    border-bottom-left-radius: 0.688rem;
    > .vehicle-image {
      width: 50%;
      padding: 6.625rem 1.875rem;
      > img {
        width: 100%;
        height: 100%;
      }
    }

    > .vehicle-image {
      width: 55%;
    }
    > .vehicle-features {
      width: 45%;
      padding: 2.25rem 1.875rem 2.25rem 0rem;
      > p {
        > .kind {
          font-size: 0.625rem;
          text-transform: uppercase;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.4;
          letter-spacing: normal;
        }
        > .title {
          font-size: 1.5rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          margin-bottom: 1rem;
        }

        > .description {
          font-size: 0.875rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }

      > .features {
        margin-top: .75rem;
        > .title {
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.71;
          letter-spacing: normal;
          margin-bottom: 0.813rem;
        }

        > .feature-list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          row-gap: .75rem;
          grid-auto-rows: auto;
        }
      }
    }
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  text-align: left;
  .disclaimer-title{
    font-size: .75rem;
    font-weight: bold;
    color: #d27b4d;
    line-height: normal;
  }
  .disclaimer-desc{
    font-size: .75rem;
  }
}